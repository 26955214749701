<template>
    <div class="text-center">
        <b-card >
            <b-spinner class="mt-1 mb-1" variant="primary" label="Spinning"></b-spinner>
            <b-card-text>
                <h5>데이터 수집중...</h5>
            </b-card-text>
        </b-card>
    </div>
</template>
<script>
import {BCard, BCardText, BSpinner} from 'bootstrap-vue'

export default {
    name: 'Loading',
    components: {
        BCard,
        BCardText,
        BSpinner
    },
};

</script>
<style scoped>

</style>