<template>
  <b-card-actions
      :title="`${farm.name} 실시간 센서 정보`"
      noActions
      bg-variant="light-info">
    <load-page v-show="!show"></load-page>
    <hr>
    <b-row v-if="thdItems.length === 0"
      class="d-flex flex-column align-items-center">
      <b >측정값이 존재하지 않습니다.</b>
    </b-row>
    <b-row
      v-show="show"
      class="d-flex flex-column align-items-center">
      <div >
        <!-- <h5 style="color:orange;font-size:1.5em;font-weight:530">※ 센서 설치 및 등록이 필요합니다. ※</h5> -->
      </div>
      
      <b-row class="d-flex flex-wrap">
        <b-col
          v-for="(item, idx) in thdItems"
          :key="item._id">
          <b-avatar
            rounded="lg"
            size="5rem"
            :variant="item.color"
          >
          <div class="avatar-content" :id="`${farm._id}_${idx}`">
            <tr>
              <td><feather-icon :icon="`${item.icon}`" size="24" ></feather-icon></td>
              <td><h5>{{ item.name }}</h5></td>
            </tr>
            <tr>
              <td colspan="2"><h4>{{ item.title }}{{ item.postfix }}</h4></td>
            </tr>
          </div>
          </b-avatar>
          <b-tooltip v-if="item.name === 'THI'" :ref="`tooltip_${farm._id}_${idx}`" :target="`${farm._id}_${idx}`" placement="bottom" show triggers="manual click">
            <h5 @click="hide(`tooltip_${farm._id}_${idx}`)" style="color:white">THI (열 스트레스 지수) <br> Temperature-Humidity Index <hr> 가축의 열 스트레스 지수를 온도와 습도를 이용하여 나타낸 수치 </h5>
            <p> 단계 : 정상 - 주의 - 경고 - 위험 </p>
          </b-tooltip>
        </b-col>
      </b-row>
      <b-row class="d-flex flex-wrap mt-1">
        <b-col
          v-for="item in sensorItems"
          :key="item._id">
          <b-avatar
            rounded="lg"
            size="5rem"
            :variant="item.color"
          >
          <div class="avatar-content">
            <tr>
              <td><feather-icon :icon="`${item.icon}`" size="24" ></feather-icon></td>
              <td><h5>{{ item.name }}</h5></td>
            </tr>
            <tr>
              <td colspan="2"><h4>{{ item.title }}{{ item.postfix }}</h4></td>
            </tr>
          </div>
          
          </b-avatar>
        </b-col>
      </b-row>
      <b-row class="mb-0">
        <h6 v-show="show"> {{ timeStamp }} 측정값</h6>
      </b-row>
    </b-row>
  </b-card-actions>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/velocity/1.2.3/velocity.min.js"></script>
<script>
import moment from 'moment'
import {
  BCardText, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BTooltip
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import LoadPage from '@/views/pages/utils/LoadPage.vue'
export default {
  components: {
    BCardActions,
    BTooltip,
    BRow,
    BCol,
    BCardText,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    LoadPage
  },
  data() {
    return {
      show: false,
      thdItems: [],
      sensorItems: [],
      intervalId: '',
      timeStamp: ''
    }
  },
  mounted: function () {
    this.show = false
    setTimeout(() => {
      this.hide()
    }, 1000 * 2.5);
  },
  created() {
    this.intervalId = setInterval(() => {
      this.refresh()
    }, 1000 * 30);
    setTimeout(() => {
      clearInterval(this.intervalId)
    }, 1000 * 60 * 5);
    
  },
  beforeDestroy() {
    if(this.intervalId) clearInterval(this.intervalId) 
  },
  /* watch: {
    thdItems() {
      return this.refresh()
    }
  }, */
  props: {
    farm: {
      type: Object,
      require: true
    }
  },
  methods: {
    hide(tooltip) {
      const target = this.$refs[`tooltip_${this.farm._id}_2`] || this.$refs[tooltip]
      if(target == null || target.length < 1) return
      return target[0].$emit('close')
    },
    async refresh() {
      this.$emit('update-sensor', this.farm)
    },
    async updateUI(sensorData) {
      this.thdItems = []
      this.sensorItems = []

      const temperatureSensor = ['temperature', 'temp_jxct']
      const humiditySensor = ['humidity', 'humi_jxct']

      if(sensorData.length === 0) {
      } else {
        sensorData.forEach(el => {
          let icon = 'CpuIcon'
          let color = 'light-primary'
          let postfix = ''
          let name = ''
          if (temperatureSensor.includes(el.type)) {
            name = '온도'
            icon = 'ThermometerIcon'
            color = 'light-danger'
            postfix = '°C'
          } 
          else if (humiditySensor.includes(el.type)) {
            name = '습도'
            icon = 'DropletIcon'
            color = 'light-info'
            postfix = '%'
          } 
          else if (el.type === 'hs2') {
            name = '황화수소'
            icon = 'AlertOctagonIcon'
            color = 'light-warning'
            postfix = 'ppm'
          } 
          else if (el.type === 'nh3'
          || el.type === 'co2_nh3') {
            name = 'NH3'
            icon = 'AlertOctagonIcon'
            color = 'light-warning'
            postfix = 'ppm'
          } 
          else if (el.type === 'rain') {
            name = '우적'
            color = 'info'
            icon = 'CloudRainIcon'
          } 
          else if (el.type === 'freeze') {
            name = '제상'
            color = 'light-danger'
            icon = 'ThermometerIcon'
            postfix = '°C'
          }
          else if (el.type === 'co2'
          || el.type === 'co2_gd') {
            name = 'CO2'
            icon = 'AlertOctagonIcon'
            color = 'light-warning'
            postfix = 'ppm'
          } 
          else if (el.type === 'wd_jxct') {
            name = '풍향'
            color = 'light-primary'
            icon = 'SendIcon'
            postfix = '°'
          }
          else if (el.type === 'ws_jxct') {
            name = '풍속'
            color = 'light-primary'
            icon = 'WindIcon'
            postfix = '㎧'
          }
          else {
            postfix = 'ppm'
          }
          let recentValue = el.val
          if(el.type === 'rain') {
            if(recentValue == '0') recentValue = '신호 대기' 
            else if(recentValue == '1') recentValue = '강우 감지' 
            else recentValue = '연결 끊김'
          } 

          this.timeStamp = moment().format('YYYY-MM-DD HH:mm:ss')
          if(temperatureSensor.includes(el.type) || humiditySensor.includes(el.type)) {
            this.thdItems.push({
              id: el._id,
              icon,
              color,
              name,
              postfix,
              title: recentValue,
              subtitle: el.name,
            })
          } else {
            if(recentValue != null) {
              this.sensorItems.push({
                id: el._id,
                icon,
                color,
                name,
                title: `${recentValue} ${postfix}`,
                subtitle: el.name,
              })
            }
          }
          
        })
        if(this.thdItems.length > 1) {
          const temp = this.thdItems[0].title
          const humi = this.thdItems[1].title
          let val = this.getHeatStressIndex(temp, humi)
          if(temp === undefined || humi === undefined) val = 0
          this.thdItems.push({
              icon: 'FrownIcon',
              color: 'light-warning',
              name: 'THI',
              title: this.getDiscomfortLevel(val),
            })
        }
        
      }
      this.show = true
      
    },
    getHeatStressIndex(temperature, humidity) {
      const index = (0.8 * temperature) + (humidity / 100 * (temperature - 14.4)) + 46.4
      return index
    },
    getDiscomfortLevel (stressPoint) {
      if(isNaN(stressPoint)) return "-" 
      else if(stressPoint <= 72) return '정상' 
      else if (stressPoint <= 78) return "주의" 
      else if (stressPoint <= 89) return "경고"
      else return "위험"
    }
  },
}
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.custom-avatar .avatar-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  text-align: center;
}
.custom-avatar .avatar-content p {
  margin: 0;
  font-size: 12px;
}
</style>