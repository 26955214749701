<template>
  <div>
    <b-card-actions
      ref="weatherCard"
      noActions
      bg-variant="info"
    >
    <h3 class="font-weight-bolder cus_mt" style="text-align: center; margin-bottom: 0;"> {{ location }}</h3>
    <hr>
    <load-page v-show="!show"></load-page>
      <b-row 
      v-show="show"
      style="margin: 0 auto;text-align:center;display:inline;">
        <b-media class="ml-0 mr-0 mt-0" >
          <template #aside>
            <b-row>
              <b-col>
                <b-img
                  :src="icon"
                  alt="weather logo"/>
                  <p style="color: black; font-size: large; width: 100%; margin: auto"> {{ weather }}</p>
              </b-col>
            </b-row>
          </template>
          <b-media-body style="margin:0 auto">
            <!-- <h3 class="font-weight-bolder">  {{ location }} </h3> -->
            <p class="subT"> 온도 : {{ temp }} ℃ </p>
            <p class="subT"> 습도 : {{ humidity }} ％ </p>
            <p class="subT"> THI : {{ getAlertStep(stressPoint) }} </p>
            <p class="subT"> 불쾌지수 : {{ getDiscomfortLevel(stressPoint) }} </p> <!-- getAlertStep -->
          </b-media-body>
          </b-media>
      </b-row>
      <!-- <hr>
      <b> Discomfort Index, DI (불쾌지수): 기온과 습도를 고려한 인간의 불쾌함을 평가</b><br>
      <b> Heat Stress Index, HSI (열 스트레스 지수) : 가축이 열 스트레스를 얼마나 받고 있는지를 평가 </b> -->
    </b-card-actions>
  </div>
</template>

<script>
import store from '@/store'
import {
  BCardText, BRow, BCol, BMedia, BMediaAside, BMediaBody, BImg,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import axios from '@axios'
import LoadPage from '@/views/pages/utils/LoadPage.vue'
export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    LoadPage
  },
  props: {
    loca: {
      latitude: {
        type: String,
        required: true
      },
      longitude: {
        type: String,
        required: true
      }
    }
  },
  data() {
    return {
      location: '',
      weather: '',
      icon: '',
      temp: '',
      temp_min: '',
      temp_max: '',
      pressure: '',
      humidity: '',
      wind_speed: '',
      wind_deg: '',
      stressPoint: 0,
      show: false
    }
  },
  computed: {
    statisticsItems() {
      return [
        {
          icon: 'ThermometerIcon',
          color: 'light-primary',
          title: '온도',
          subtitle: `${this.temp} ℃`,
        },
        {
          icon: 'DropletIcon',
          color: 'light-info',
          title: '습도',
          subtitle: `${this.humidity} ％`,
        },
        {
          icon: 'TrendingUpIcon',
          color: 'light-danger',
          title: '최고 온도',
          subtitle: `${this.temp_max} °C`,
        },
        {
          icon: 'TrendingDownIcon',
          color: 'light-danger',
          title: '최저 온도',
          subtitle: `${this.temp_min} °C`,
        },
        {
          icon: 'WindIcon',
          color: 'light-success',
          title: '풍속',
          subtitle: `${this.wind_speed} km/h`,
        },
        {
          icon: 'DownloadCloudIcon',
          color: 'light-success',
          title: '기압',
          subtitle: `${this.pressure} hpa`,
        },
      ]
    },
  },
  created() {
    this.getWeatherData()
    
  },
  methods: {
    getCurrentTime() {
      return new Date().toLocaleString()
    },
    async getWeatherData() {
      let user = store.state.users.user
      
      let parmas = {}
      if(this.loca) {
        parmas = {
          lat: this.loca.latitude,
          lon: this.loca.longitude
        }
      } else {
        parmas = {
          lat: (user !== null) ? user.lat : '37.558131',
          lon: (user !== null) ? user.lon : '126.945476',
        }
      }

      try {
        const result = await axios.get('/utils/weather', { params: parmas })
        this.location = result.data.location.toUpperCase()
        this.temp = result.data.temp.toFixed(1)
        this.pressure = result.data.pressure
        this.temp_max = result.data.temp_max.toFixed(2)
        this.temp_min = result.data.temp_min.toFixed(2)
        this.humidity = result.data.humidity
        this.weather = result.data.weather
        this.wind_deg = result.data.wind_deg
        this.wind_speed = result.data.wind_speed
        this.icon = result.data.icon
        this.$refs.weatherCard.showLoading = false
        this.show = true
        this.stressPoint = this.getHeatStressIndex(this.temp, this.humidity)
      } catch (err) {
        alert('위치를 불러올 수 없습니다')
        //console.log(err)
        //this.getWeatherData()
      }

    },

    getDiscomfortLevel (stressPoint) {
      if(stressPoint <= 68) return '매우 쾌적' 
      else if (stressPoint <= 74) return "쾌적"
      else if (stressPoint <= 79) return "다소 불쾌" 
      else if (stressPoint <= 83) return "불쾌"
      else return '매우 불쾌'
    },
    getAlertStep (stressPoint) {
      if(isNaN(stressPoint)) return "측정 불가" 
      else if(stressPoint <= 72) return '정상' 
      else if (stressPoint <= 78) return "주의" 
      else if (stressPoint <= 89) return "경고"
      else return "위험"
    },
    getHeatStressIndex(temperature, humidity) {
      return (0.8 * temperature) + (humidity / 100 * (temperature - 14.4)) + 46.4
    },
    getDiscomfortIndex(temperature, humidity) {
      const index = (1.8 * temperature ) - 0.55 * (1 - humidity / 100) * (1.8 * temperature - 26) + 32
      return index
    }

  },
}
</script>
<style scoped>
b {
  text-align: center;
}
.subT {
  font-size: large;
  font-weight: 600;
}
.cus_mt {
  margin-top: 0.5rem;
}
</style>

